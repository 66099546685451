import { request } from "/src/utils/index.js";
import { download } from "/src/utils/download.js";

export function Summary(params) {
  return request.get("b2b/PrescriptionFlow/summary", { params });
}

export function Detail(params) {
  return request.get("b2b/PrescriptionFlow/detail", { params });
}

export function Limite(params) {
  return request.get(
    "b2b/PrescriptionFlow/confirmation/order/canGenerate",
    params
  );
}

export function Clubs(params) {
  return request.get("b2b/business/club/relation/list", { params });
}


export function DownloadSummary(params) {
  return download.get("b2b/PrescriptionFlow/summary/download", { params });
}


export function DownloadDetail(params) {
  return download.get("b2b/PrescriptionFlow/detail/download", { params });
}

