import XLSX from "xlsx";
import { message } from "ant-design-vue";

function doExcel(tableID, filename) {
  // 如果未提供文件名，则默认使用当前日期时间作为文件名
  var fname = filename
    ? filename + ".xlsx"
    : "export-" + new Date().toISOString().replace(/[\-\:\.]/g, "") + ".xlsx";

  // 获取指定 ID 的表格元素
  var table = document.getElementById(tableID);

  // 将 HTML 表格转换为工作表
  var wb = XLSX.utils.table_to_book(table, { sheet: "Sheet1" });

  // 生成 Excel 文件并触发下载
  XLSX.writeFile(wb, fname);
}
export { doExcel };
