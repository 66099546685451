<template>
  <div>
    <div>
      <div class="searchArea">

        <!-- <a-range-picker v-model:value="inputs.patroltime" :placeholder="['应巡开始时间','应巡结束时间']" :locale="locale" /> -->
        <a-select v-if="$store.state.club.category=='事业部'" v-model:value="inputs.clubId" placeholder="医院" allowClear>
          <!-- <a-select-option v-for="item in $store.state.areaNameType" :value="item.NAME">{{ item.NAME }}</a-select-option> -->
          <a-select-option value="0">全部医院</a-select-option>
          <a-select-option v-for="item in option" :value="item.id">{{item.name}}</a-select-option>
        </a-select>

        <a-range-picker v-model:value="inputs.date" :ranges="ranges" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" :placeholder="['开始日期','结束日期']" />

        <a-button type="primary" shape="circle" @click="search('')">
          <template #icon>
            <SearchOutlined />
          </template>
        </a-button>
        <!-- <a-button style="margin-left: 10px" @click="resetForm">重置</a-button> -->
        <a-button style="margin-left: 10px" type="primary" @click="exportExcel()">
          <template #icon>
            <PlusOutlined />
          </template>
          导出清单
        </a-button>

        <a-button v-if="canGenerate" style="margin-left: 10px" type="primary" @click="generatePerformanceVerification">
          <template #icon>
            <PlusOutlined />
          </template>
          生成核对单
        </a-button>

      </div>

      <a-table id="monthlyReportTable" class="ant-table-striped" :columns="columns" :data-source="data" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" bordered rowKey="id">

        <template #operation="{ record }">
          <div class="flex_sa" v-if="record.prescriptionCount>0">
            <!-- <a @click="getConsultation(record)">查看处方</a> -->
          </div>
        </template>
        <template #footer>
          <div class="tfoot">
            <div style="width: 10%;">合计</div>
            <div style="width: 67%;"></div>
            <div style="width: 11.5%;">{{amountCount}}</div>
            <div style="width: 11.5%;">{{standardAmountCount}}</div>
          </div>
        </template>
      </a-table>
    </div>

    <generatePerformanceVerification v-model="visible" :Data="inputs" />
  </div>
</template>

<style scoped>

.online {
  background: #40a9ff;
  border-color: #40a9ff;
}

.offline {
  background: #979596;
  border-color: #979596;
}

:deep(.ant-table-tbody > tr > td, .ant-table-thead > tr > th) {
  padding: 10px 8px;
}

.table_btn {
  width: 70px;
  display: inline-block;
  text-align: center;
}

.clickable {
  /* background-color: #000; */
  width: 100%;
  height: 100%;
}

.tfoot {
  display: flex;
  width: 100%;
}

.tfoot > div {
  width: 15%;
  text-align: center;
}

.tfoot > div:nth-child(2) {
  width: 25%;
}

/* 
.tfoot > div:nth-child(8) {
  width: 20%;
}
.tfoot > div:nth-child(9) {
  width: 10%;
} */
:deep(.ant-table-footer) {
  padding: 16px 0;
}
</style>


<script>
import {
  SearchOutlined,
  PlusOutlined,
  DownloadOutlined,
  CheckCircleFilled,
  WarningFilled,
  FormOutlined,
} from "@ant-design/icons-vue";
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import moment from 'moment';
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { Summary, Clubs, Limite } from "/src/api/b2b/prescriptionFlow.js";
import generatePerformanceVerification from "/src/components/performanceVerification/generatePerformanceVerification.vue";
import { doExcel } from "/src/utils/doExcel.js";

export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    DownloadOutlined,
    locale,
    CheckCircleFilled,
    WarningFilled,
    FormOutlined,
    generatePerformanceVerification
  },
  name: "汇总报表",
  data() {
    return {
      modalBtn: "",
      modalIndex: "0",
      visible: false,
      active: undefined,
      id: undefined,
      selectedRow: {},
      canGenerate: false,
      total: 0,
      current: 1,
      // pageSize: 10,
      inputs: {
        clubId: undefined,
        date: [moment(), moment()],
      },
      option: [],
      ranges: {
        "今天": [moment(), moment()],
        "昨天": [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        "近7天": [moment().subtract(6, 'days'), moment()],
        "近30天": [moment().subtract(29, 'days'), moment()],
      },
      modelInput: {
        name: undefined,
        gender: undefined,
        nameInitial: undefined,
        club: true,
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      option: [{ value: "黄浦区" }, { value: "浦东新区" }, { value: "长宁区" }],
      columns: [

        {
          title: "期间",
          key: "date",
          dataIndex: "date",
          width: "15%",
          slots: {
            customRender: "date"
          }
        },
        {
          title: "医院名称",
          key: "clubName",
          dataIndex: "clubName",
          ellipsis: true,
          width: "25%",
          slots: {
            customRender: "clubName"
          },
        },
        {
          title: "预约数",
          key: "appointmentCount",
          dataIndex: "appointmentCount",
          // ellipsis: true,
          width: "15%",
          slots: {
            customRender: "appointmentCount"
          }
        },
        {
          title: "问诊数",
          key: "healthAssessmentCount",
          dataIndex: "healthAssessmentCount",
          // ellipsis: true,
          width: "15%",
          slots: {
            customRender: "healthAssessmentCount"
          }
        },
        {
          title: "脉诊数",
          key: "mzCount",
          dataIndex: "mzCount",
          // ellipsis: true,
          width: "15%",
          slots: {
            customRender: "mzCount"
          }
        },
        {
          title: "处方数",
          key: "prescriptionCount",
          dataIndex: "prescriptionCount",
          // ellipsis: true,
          width: "15%",
          slots: {
            customRender: "prescriptionCount"
          }
        },
        {
          title: "处方金额",
          key: "amount",
          dataIndex: "amount",
          // ellipsis: true,
          width: "15%",
          slots: {
            customRender: "amount"
          }
        },
        {
          title: "处方金额(标准)",
          key: "standardAmount",
          dataIndex: "standardAmount",
          // ellipsis: true,
          width: "15%",
          slots: {
            customRender: "standardAmount"
          }
        },


        // {
        //   title: "操作",
        //   dataIndex: "operation",
        //   width: "10%",
        //   slots: {
        //     customRender: "operation"
        //   }
        // }
      ],
      amountCount: 0,
      standardAmountCount: 0,
      data: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          this.selectedRows = selectedRows;
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        }
      },
      editableData: reactive({}),
      selectedRows: {},
      // doExcel: doExcel
    };
  },
  created() { },
  mounted() {
    this.search("");
    this.getOption();
    this.getLimite();
  },
  activated() {
    if (localStorage.getItem("refrashProject") == "true") {
      // this.search(1);
    }
    localStorage.setItem("refrashProject", false);
  },
  methods: {

    closeModal(value) {
      this[value] = false;
    },

    async exportExcel() {

      let startDate = typeof this.inputs.date[0] == "string" ? this.inputs.date[0] : this.inputs.date[0].format("YYYY-MM-DD")
      let endDate = typeof this.inputs.date[1] == "string" ? this.inputs.date[1] : this.inputs.date[1].format("YYYY-MM-DD")
      doExcel("monthlyReportTable", "汇总报表" + startDate + "-" + endDate)
      // await DownloadSummary({ startDate, endDate, clubId: this.inputs.clubId })
      // let clubId = this.inputs.clubId ? this.inputs.clubId : this.$store.state.club.id
      // console.log(clubId);

      // let host = window.location.protocol + "//" + window.location.hostname
      // let url = `${host}:9443/b2b/PrescriptionFlow/summary/download?startDate=${startDate}&endDate=${endDate}&clubId=${clubId}`
      // window.open(url)
    },
    generatePerformanceVerification(record) {
      this.visible = true
      this.selectedRow = { ...record }
    },
    async getLimite() {
      let res = await Limite()
      console.log(res);
      this.canGenerate = res.data
    },
    async getOption() {
      let res = await Clubs({ category: '门诊部' })
      this.option = res.data
      console.log(this.option);

    },
    resetForm() {
      this.inputs = {
        name: undefined,
        gender: undefined,
        isStandard: undefined,
      }
    },
    async onDelete(id) {
      this.editableData = {}
      let res = await Delete({ id })
      this.data = this.data.filter(function (item) {
        return item.id !== id;
      });
    },
    edit(id) {
      let _data = this.data;
      this.editableData[id] = cloneDeep(
        _data.filter(item => id === item.id)[0]
      );
    },
    async save(id) {
      let _data = this.data;
      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        delete this.editableData[id];
        return;
      }
      let params = {
        id: this.editableData[id].id,
        name: this.editableData[id].name,
        nameInitial: this.editableData[id].nameInitial,
        gender: this.editableData[id].gender,
      }
      console.log(params);
      let res = await Update(params)
      if (res.errCode == 0) {
        Object.assign(
          _data.filter(item => id === item.id)[0],
          this.editableData[id]
        );
        delete this.editableData[id];
      }
    },
    cancel(id) {
      delete this.editableData[id];
    },
    tabsName(tabName) {
      this.$store.commit("changePage", { name: tabName.name, query: tabName.query });
    },
    async search(pagination) {
      let _this = this;
      let startDate = typeof this.inputs.date[0] == "string" ? this.inputs.date[0] : this.inputs.date[0].format("YYYY-MM-DD")
      let endDate = typeof this.inputs.date[1] == "string" ? this.inputs.date[1] : this.inputs.date[1].format("YYYY-MM-DD")



      let params = {
        name: this.inputs.name,
        nameInitial: this.inputs.nameInitial,
        gender: this.inputs.gender,
        isStandard: this.inputs.isStandard,
        clubId: this.inputs.clubId,
        pageSize: this.$store.state.tableSize,
        startDate,
        endDate,
      };
      if (pagination == "") {
        params.current = 1
      } else {
        params.current = pagination.current
        this.current = pagination.current;
      }

      let res = await Summary(params)
      if (res.errCode == 0) {
        if (pagination == "") {
          // this.$message.success("查询成功", function () { });
          this.current = 1;
        }
        this.total = res.data.total;
        this.data = res.data;
        this.standardAmountCount = 0
        this.amountCount = 0
        this.data.forEach((item) => {
          this.amountCount += item.amount ? Number(item.amount) : 0
          this.standardAmountCount += item.standardAmount ? Number(item.standardAmount) : 0
        })
        this.amountCount = this.amountCount.toFixed(2)
        this.standardAmountCount = this.standardAmountCount.toFixed(2)
      } else {
        this.$message.error("查询失败", function () { });
      }
    },
    async changePage(pagination) {
      let _this = this;
      this.current = pagination.current;
      this.editableData = {}
      let params = {
        name: this.inputs.name,
        nameInitial: this.inputs.nameInitial,
        gender: this.inputs.gender,
        isStandard: this.inputs.isStandard,
        current: this.current,
        pageSize: this.$store.state.tableSize,
        includeMifang: 1,
        includeJingfang: 1,
      };

      let res = await Summary(params)
      if (res.errCode == 0) {
        this.total = res.data.total;
        this.data = res.data.records
      } else {
        this.$message.error("查询失败", function () { });
      }
    },


  }
};
</script>