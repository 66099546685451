import { request } from "/src/utils/index.js";

export function Search(params) {
  // console.log(parmas);
  return request.get("sdcom/club/list", { params });
}
export function SearchAllclubList(params) {
  // console.log(parmas);
  return request.get("sdcom/club/list/?category=门诊部");
}

export function Detail(params) {
  // console.log(parmas);
  return request.get("sys/club/menu" + params);
}

export function Add(params) {
  return request.post("sys/club/menu", params);
}

export function Update(params) {
  return request.put("sys/club/menu", params);
}

export function Delete(data) {
  return request.delete("sys/club/menu", { data });
}
