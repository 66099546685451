import { request } from "/src/utils/index.js";

export function Search(params) {
  return request.get("b2b/PrescriptionFlow/confirmation/order/list", {
    params,
  });
}

export function Add(params) {
  return request.get("b2b/PrescriptionFlow/confirmation/order/generate", {
    params,
  });
}

export function getOption(params) {
  return request.get("b2b/PrescriptionFlow/confirmation/order/operation/list", {
    params,
  });
}

export function Detail(params) {
  return request.get("b2b/PrescriptionFlow/confirmation/order/" + params);
}

export function Generate(params) {
  return request.post(
    "b2b/PrescriptionFlow/confirmation/order/generate",
    params
  );
}

export function Operation(params) {
  return request.post(
    "b2b/PrescriptionFlow/confirmation/order/operation/perform",
    params
  );
}
