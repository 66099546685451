<template>
  <div>
    <a-modal v-model:visible="visible" title="医院流水核对单" @ok="handleOk" okText="新增" cancelText="取消" ok :afterClose="closeModal" width="35vw" :cancelButtonProps="{ style: { display: 'none' }}" :okButtonProps="{ style: { display: 'none' }}" class="noFooter">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <div class="flex2">
          <a-form-item label="医院">
            {{data?.clubName}}
          </a-form-item>
        </div>
        <div class="flex2">
          <a-form-item label="期间">
            {{data?.startDate}} 至 {{data?.endDate}}
          </a-form-item>
          <a-form-item label="状态">
            {{data?.status}}
          </a-form-item>
        </div>
        <div class="flex2">
          <a-form-item label="系统流水">
            {{data?.statsAmount}}
          </a-form-item>
          <a-form-item label="实际流水">
            <a-input v-if="data?.status=='已创建'" v-model:value="this.data.actualAmount" />
            <span v-else>{{data.actualAmount}}</span>
          </a-form-item>
        </div>
        <div class="flex2">
          <a-form-item label="流水输入" v-if="data?.businessOperatorName">
            {{data?.businessOperatorName}}
          </a-form-item>
          <a-form-item label="医院确认" v-if="data?.hospitalOperatorName">
            <span>{{data?.hospitalOperatorName}}</span>
          </a-form-item>
        </div>
      </a-form>

      <div class="flex_center" style="margin-top: 20px;">
        <a-button type="primary" v-for="item in option" @click="next(item)">{{item}}</a-button>
        <!-- <a-button >{{option[0]}}</a-button> -->
        <a-button @click="closeModal">关闭</a-button>
      </div>
    </a-modal>

  </div>
</template>

<script>
import { reactive } from "vue";
import { Generate, getOption, Detail, Operation } from "/src/api/b2b/performanceVerification.js";
export default {
  components: {

  },
  data() {
    return {
      visible: this.modelValue,
      option: [],
      medicine: "",
      data: {
        name: undefined,
        description: undefined,
      },
      labelCol: {
        span: 8,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {

  },
  activated() {
  },
  //获取父元素传值
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    Data: {
      type: Boolean,
      default: false,
    }
  },
  computed: {

  },
  methods: {
    async generate() {
      let _this = this;
      let obj = {
        clubId: this.Data.clubId,
        startDate: typeof this.Data.date[0] == "string" ? this.Data.date[0] : this.Data.date[0].format("YYYY-MM-DD"),
        endDate: typeof this.Data.date[1] == "string" ? this.Data.date[1] : this.Data.date[1].format("YYYY-MM-DD"),
      }
      let res = await Generate(obj)
      console.log(res);

      if (res.errCode == 0) {
        this.data = res.data
        this.getOption(res.data.id)
      } else {
        this.closeModal()
      }
    },
    async getDetail() {
      let _this = this;

      let res = await Detail(this.data.id)
      console.log(res);

      if (res.errCode == 0) {
        this.data = res.data
        this.getOption(res.data.id)
      }
    },
    async getOption(id) {
      let _this = this;

      let res = await getOption({ id })
      console.log(res);

      if (res.errCode == 0) {
        this.option = []
        res.data.forEach(item => {
          this.option.push(item.nextOperation)
        })
        console.log(this.option);

      }
    },
    async next(operation) {
      let _this = this;

      let res = await Operation({ id: this.data.id, operation, object: { actualAmount: this.data.actualAmount } })
      console.log(res);

      if (res.errCode == 0) {
        this.getDetail()
        if (operation == '删除') {
          this.closeModal()
        }
      }
    },
    async handleOk() {
      let _this = this;

      let res = await Add(this.data)
      if (res.errCode == 0) {
        _this.$message.success("新建成功", function () { });
        _this.visible = false
        this.data = {
          name: undefined,
          description: undefined,
        }
      }
    },
    closeModal() {
      this.data = {}
      this.$emit('update:modelValue', false);
    }
    //初始化data所有数据，并关闭modal
  },
  watch: {
    modelValue(val) {
      this.visible = val
      console.log(this.visible);

      if (this.visible) {
        this.generate()
      }
    },
  }
};
</script>

<style scoped>
.head {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
}
.head span {
  font-weight: bold;
  margin-right: 10px;
}
.ant-modal-footer {
  display: none;
}
</style>
