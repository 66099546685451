import { createStore } from "vuex";
import router from "../router";
import { message } from "ant-design-vue";
import { login, UserInfo } from "/src/api/login.js";
import { getClub } from "/src/api/selectClub.js";
import { getOption } from "/src/api/login.js";
import { Search as searchMedicine } from "/src/api/medicine.js";
import { Search as searchPrescription } from "/src/api/prescription.js";
import { Search as searchSyndromeList } from "/src/api/syndrome.js";
import { Search as searchPermissionList } from "/src/api/permission.js";
import { Search as searchEmployeeList } from "/src/api/employee.js";
import { Search as searchRoleList } from "/src/api/role.js";
import { SearchAllclubList as SearchAllclubList } from "/src/api/club.js";

export default createStore({
  state: {
    pageState: "login",
    // 登录人信息
    employee: {},
    token: "",
    loginAuthority: "", //权限
    pages: [{ name: "疾病名管理", link: "/" }],
    // pages: [{ name: "疾病名管理", link: "/" }],
    activeKey: 0,
    clubs: [],
    club: {},
    selectClub: {
      visible: false,
      control: false,
    },
    tableSize: 12,
    // openKeys: ["学术管理"],
    openKeys: [],
    prescriptionType: [
      {
        name: "经方",
        aiCategory: "Jingfang",
        type: "中药处方",
        category: "Jingfang",
      },

      {
        name: "秘方",
        aiCategory: "Mifang",
        type: "中药处方",
        category: "Mifang",
      },

      {
        name: "药对",
        aiCategory: "药对",
        type: "中药处方",
        category: "药对",
      },
      {
        name: "疾病穴位处方",
        aiCategory: "疾病穴位处方",
        type: "穴位处方",
        category: "穴位处方",
      },
      {
        name: "症候穴位处方",
        aiCategory: "症候穴位处方",
        type: "穴位处方",
        category: "穴位处方",
      },
    ],
    selectedKeys: [],
    links: [
      // {
      //   name: "日常管理统计",
      //   subMenus: [{ name: "员工管理" }, { name: "门诊日报" }],
      // },
    ],
    medicineList: [],
    prescriptionList: [],
    syndromeList: [],
    permissionList: [],
    employeeList: [],
    roleList: [],
    allclubList: [],
  },
  mutations: {
    // 登录功能，获取token、所在门诊、功能
    async getUserInfo(state, userInfo) {
      //登录返回token、所在所有门诊
      let token;
      let clubs;
      let employee;
      let res;
      // console.log(!localStorage.getItem("token"));

      if (userInfo.type == "auto") {
        if (localStorage.getItem("token")) {
          token = localStorage.getItem("token");
          res = await UserInfo();
        } else {
          localStorage.setItem("token", "");
          return;
        }
      } else {
        res = await login(userInfo);

        token = res.data.jwt;
      }

      clubs = res.data.clubs;
      employee = res.data.employee;

      //将token、所在门诊、员工信息存入localStorage
      localStorage.setItem("token", token);
      // localStorage.setItem("clubs", clubs);
      // localStorage.setItem("employee", employee);
      // state.token = localStorage.getItem("token");
      state.clubs = res.data.clubs;
      state.employee = res.data.employee;

      let res2 = await getClub();

      state.club = res2.data;
      if (!res2.data) {
        state.selectClub.visible = true;
        state.selectClub.control = false;
      }
      //判断state.club是否为空
      //获取用户所有可用功能
      if (state?.club?.id) {
        let res3 = await getOption();
        if (res3.errCode == 0) {
          state.links = res3.data;
        }
        // console.log(state.links);

        if (!state.links[0]?.subMenus) {
          message.warning("您在当前门店暂无权限！");
        } else if (userInfo.type != "auto") {
          if (!state.links[0]?.subMenus[0]?.routePath) {
            message.warning("当前路径存在异常，请手动切换菜单");
          }
          await router.push({
            path: state.links[0]?.subMenus[0]?.routePath
              ? state.links[0]?.subMenus[0]?.routePath
              : "/",
          });
        }
        state.pages = [
          {
            name: state.links[0]?.subMenus[0]?.name,
            link: state.links[0]?.subMenus[0]?.routePath
              ? state.links[0]?.subMenus[0]?.routePath
              : "/",
          },
        ];

        let name = router.currentRoute.value.name;
        state.openKeys = name;
        state.selectedKeys = [name];
        this.commit("changePage", {
          name,
        });
      }

      // state.clubs = res2.data;
      state.pageState = "home";
      message.success("登录成功！");
      //登录后获取全局的数据
      this.dispatch("getMedicineList");
      this.dispatch("getPrescriptionList");
      this.dispatch("getSyndromeList");
      this.dispatch("getPermissionList");
      this.dispatch("getEmployeeList");
      this.dispatch("getRoleList");
      this.dispatch("getAllclubList");
    },

    // 跳转页面并更改顶部和左侧标签
    changePage(state, tabName) {
      //判断是否已开启标签
      let isOpen = false;
      state.pages.forEach((element, i) => {
        // 点击对应pages(标签栏)第几项
        if (tabName.name == element.name) {
          isOpen = true;
        }
      });

      router.options.routes.forEach((element, i) => {
        if (element.name == tabName.name) {
          // 没有此标签则添加
          if (!isOpen) {
            state.pages.push({
              name: element.name,
              link: element.path,
            });
          }

          router.push({ path: element.path, query: tabName.query });
          // console.log("查看成分：" + router.options.routes[i]?.meta?.keepAlive)
          if (router.options.routes[i]?.meta?.keepAlive != undefined) {
            router.options.routes[i].meta.keepAlive = true;
          }

          //启动过快导致keepAlive修改无效，先跳转空白页，刷新一次页面
          // setTimeout(() => {
          //   router.push("/blank");
          // }, 10);
          // console.log(tabName.query);
          setTimeout(() => {
            router.push({ path: element.path, query: tabName.query });
          }, 20);

          state.pages.forEach((element, i) => {
            if (tabName.name == element.name) {
              state.activeKey = i;
            }
          });
          // alert(router.options.routes[i].meta.keepAlive)
        }
      });

      this.commit("changeKey", tabName.name);
      if (tabName.delete != undefined) {
        state.pages.forEach((element, i) => {
          if (element.name == tabName.delete) {
            state.pages.splice(i, 1);
          }
        });
      }
    },
    //更改顶部导航栏
    changeKey(state, tabName) {
      // console.log(tabName)

      const childToParentMap = {};
      // 遍历links数组构造映射
      state.links.forEach((parent) => {
        parent.subMenus.forEach((child) => {
          childToParentMap[child.name] = parent.name;
        });
      });
      const parent = childToParentMap[tabName];
      if (parent) {
        // 示例：查找父级名称
        state.openKeys = [childToParentMap[tabName]];
        state.selectedKeys = [tabName];
      } else {
        state.openKeys = [];
        state.selectedKeys = [];
      }
    },
    setMedicineList(state, data) {
      state.medicineList = data;
    },
    setPrescriptionList(state, data) {
      state.prescriptionList = data;
    },
    setSyndromeListList(state, data) {
      state.syndromeList = data;
    },
    setPermissionList(state, data) {
      state.permissionList = data;
    },
    setEmployeeList(state, data) {
      state.employeeList = data;
    },
    setRoleList(state, data) {
      state.roleList = data;
    },
    setAllClubList(state, data) {
      state.allClubList = data;
    },
  },
  actions: {
    async getMedicineList() {
      let res = await searchMedicine({ pageSize: -1 });
      if (res.errCode == 0) {
        this.commit("setMedicineList", res.data);
      }
    },
    async getPrescriptionList() {
      let res = await searchPrescription({ includePrescription: true });
      if (res.errCode == 0) {
        this.commit("setPrescriptionList", res.data);
      }
    },
    async getSyndromeList() {
      let res = await searchSyndromeList({ isStandard: true });
      if (res.errCode == 0) {
        this.commit("setSyndromeListList", res.data);
      }
    },
    async getPermissionList() {
      let res = await searchPermissionList();
      if (res.errCode == 0) {
        this.commit("setPermissionList", res.data);
      }
    },
    async getEmployeeList() {
      let res = await searchEmployeeList({ clubId: 310 });
      if (res.errCode == 0) {
        this.commit("setEmployeeList", res.data.records);
      }
    },
    async getRoleList() {
      let res = await searchRoleList();
      if (res.errCode == 0) {
        this.commit("setRoleList", res.data);
      }
    },
    async getAllclubList() {
      let res = await SearchAllclubList();
      if (res.errCode == 0) {
        this.commit("setAllClubList", res.data);
      }
    },
  },
  modules: {},
});
