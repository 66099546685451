import axios from "axios";
import { message } from "ant-design-vue";
import router from "../router";
import store from "../store";

let download;
axios.defaults.timeout = 2000;


if (
  window.location.href.indexOf("localhost") != -1 ||
  window.location.href.indexOf("http://192.168.1.27") != -1
) {
  download = axios.create({
    baseURL: "/api/",
    // baseURL: "https://www.shidaojiankang.cn:9443",
    timeout: 2000,
  });
} else if (window.location.href.indexOf("shidaojiankang.cn") != -1) {
  download = axios.create({
    baseURL: "/api/",
    // baseURL: "https://www.shidaojiankang.cn:9443",
    timeout: 5000,
  });
} else {
  console.log(
    "请求失败，未配置环境地址-request.js,当前地址：" + window.location.href
  );
  download = axios.create({
    baseURL: "未知",
    timeout: 2000,
  });
}

download.interceptors.request.use(
  (config) => {
    // message.success("搜索前", function () { });
    //缓存token
    let token = store.state.token
      ? store.state.token
      : localStorage.getItem("token");
    if (token) {
      config.headers.sdJwt = token;
    }
    store.state.loading = true;
    return config;
  },
  (error) => {
    message.error("数据发送失败", function () {});
    store.state.loading = false;
    return Promise.reject(error);
  }
);

download.interceptors.response.use(
  (response) => {
    // message.success("搜索成功", function () {});
    store.state.loading = false;
    console.log(response.data);

    const contentType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; // MIME类型，根据文件类型调整

    downloadBase64File(response.data, "fileName", contentType);

    return response.data;
  },
  (error) => {
    message.error("数据返回出错" + error, function () {});
    store.state.loading = false;
    return Promise.reject(error);
  }
);
export { download };
