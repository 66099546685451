<template>
  <div>
    <div>
      <div class="searchArea">

        <!-- <a-range-picker v-model:value="inputs.patroltime" :placeholder="['应巡开始时间','应巡结束时间']" :locale="locale" /> -->
        <a-select v-if="$store.state.club.category=='事业部'" v-model:value="inputs.clubId" placeholder="医院" allowClear>
          <!-- <a-select-option v-for="item in $store.state.areaNameType" :value="item.NAME">{{ item.NAME }}</a-select-option> -->
          <a-select-option v-for="item in option" :value="item.id">{{item.name}}</a-select-option>
        </a-select>

        <a-range-picker v-model:value="inputs.date" :ranges="ranges" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" :placeholder="['开始日期','结束日期']" />

        <a-select v-model:value="inputs.category" placeholder="选择类型" allowClear>
          <!-- <a-select-option v-for="item in $store.state.areaNameType" :value="item.NAME">{{ item.NAME }}</a-select-option> -->
          <a-select-option value="预约">预约</a-select-option>
          <a-select-option value="问诊">问诊</a-select-option>
          <a-select-option value="脉诊">脉诊</a-select-option>
          <a-select-option value="处方费用">处方费用</a-select-option>
        </a-select>

        <a-button type="primary" shape="circle" @click="search('')">
          <template #icon>
            <SearchOutlined />
          </template>
        </a-button>
        <!-- <a-button style="margin-left: 10px" @click="resetForm">重置</a-button> -->
        <a-button style="margin-left: 10px" type="primary" @click="exportExcel()">
          <template #icon>
            <PlusOutlined />
          </template>
          导出清单
        </a-button>

        <!-- <a-button v-if="canGenerate" style="margin-left: 10px" type="primary" @click="generatePerformanceVerification">
          <template #icon>
            <PlusOutlined />
          </template>
          生成核对单
        </a-button> -->
      </div>

      <a-table id="listReportsTalbe" class="ant-table-striped" :columns="columns" :data-source="data" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped rowHeight2' : 'rowHeight')" bordered :pagination="false" rowKey="id">

        <template #index="{ index }">
          {{index+1}}
        </template>
        <template #operation="{ record }">
          <div class="flex_sa" v-if="record.prescriptionCount>0">
            <a @click="getConsultation(record)">查看处方</a>
          </div>
        </template>
        <template #footer v-if="showCount">
          <div class="tfoot">
            <div style="width: 10%;">合计</div>
            <div style="width: 75%;"></div>
            <div style="width: 15%">{{count}}</div>
          </div>
        </template>
      </a-table>
    </div>

    <!-- <generatePerformanceVerification v-model="visible" :Data="inputs" /> -->

  </div>
</template>

<style scoped>
.online {
  background: #40a9ff;
  border-color: #40a9ff;
}

.offline {
  background: #979596;
  border-color: #979596;
}

:deep(.ant-table-tbody > tr > td, .ant-table-thead > tr > th) {
  padding: 10px 8px;
}

.table_btn {
  width: 70px;
  display: inline-block;
  text-align: center;
}
.clickable {
  /* background-color: #000; */
  width: 100%;
  height: 100%;
}

.tfoot {
  display: flex;
  width: 100%;
}

.tfoot > div {
  width: 20%;
  text-align: center;
}

/* .tfoot > div:nth-child(2) {
  width: 25%;
} */

:deep(.ant-table-footer) {
  padding: 16px 0;
}
</style>


<script>
import {
  SearchOutlined,
  PlusOutlined,
  DownloadOutlined,
  CheckCircleFilled,
  WarningFilled,
  FormOutlined,
} from "@ant-design/icons-vue";
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import moment from 'moment';
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { Detail, Limite, Clubs, DownloadDetail } from "/src/api/b2b/prescriptionFlow.js";
import getConsultation from "/src/components/consultation/getConsultation.vue";
// import generatePerformanceVerification from "/src/components/performanceVerification/generatePerformanceVerification.vue";
import { doExcel } from "/src/utils/doExcel.js";

export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    DownloadOutlined,
    locale,
    CheckCircleFilled,
    WarningFilled,
    FormOutlined,
    getConsultation,
    // generatePerformanceVerification
  },
  name: "清单报表",
  data() {
    return {
      modalBtn: "",
      modalIndex: "0",
      visible: false,
      active: undefined,
      id: undefined,
      canGenerate: false,
      selectedRow: {},
      total: 0,
      current: 1,
      showCount: false,
      count: 0,
      pageSize: 10,
      inputs: {
        clubId: undefined,
        date: [moment(), moment()],
        category: undefined
      },
      option: [],
      ranges: {
        "今天": [moment(), moment()],
        "昨天": [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        "近7天": [moment().subtract(6, 'days'), moment()],
        "近30天": [moment().subtract(29, 'days'), moment()],
      },
      modelInput: {
        name: undefined,
        gender: undefined,
        nameInitial: undefined,
        club: true,
      },
      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      option: [{ value: "黄浦区" }, { value: "浦东新区" }, { value: "长宁区" }],
      columns: [
        {
          title: "序号",
          key: "index",
          dataIndex: "index",
          width: "10%",
          slots: {
            customRender: "index"
          }
        },
        {
          title: "日期",
          key: "date",
          dataIndex: "date",
          width: "20%",
          slots: {
            customRender: "date"
          },
          sorter: (a, b) => new Date(a.date) - new Date(b.date), // 比较两个日期对象
          sortDirections: ['ascend', 'descend'], // 设置允许的排序方向
        },
        {
          title: "医院名称",
          key: "clubName",
          dataIndex: "clubName",
          ellipsis: true,
          width: "25%",
          slots: {
            customRender: "clubName"
          },
        },




        // {
        //   title: "操作",
        //   dataIndex: "operation",
        //   width: "10%",
        //   slots: {
        //     customRender: "operation"
        //   }
        // }
      ],
      data: [],
      noData: true,
      doctorName: {
        title: "医生",
        width: "15%",
        key: "doctorName",
        dataIndex: "doctorName",
        slots: {
          customRender: "doctorName"
        },

        sorter: (a, b) => {
          // 定义获取字符串的Unicode编码值总和的逻辑
          const getUnicodeSum = (str) =>
            str ? str.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0) : 0;

          // 检查 name 是否为空，并为 null 或 undefined 提供默认值
          const unicodeSumA = a.doctorName ? getUnicodeSum(a.doctorName) : 0;
          const unicodeSumB = b.doctorName ? getUnicodeSum(b.doctorName) : 0;

          if (!a.doctorName && b.doctorName) return -1; // 如果 a.name 是空，b.name 不是空，则 a 排在前面
          if (a.doctorName && !b.doctorName) return 1;  // 如果 b.name 是空，a.name 不是空，则 b 排在前面

          if (unicodeSumA < unicodeSumB) return -1;
          if (unicodeSumA > unicodeSumB) return 1;

          // 如果编码相同，可以考虑添加第二个排序标准，比如年龄
          return a.age - b.age;
        },
        sortDirections: ['descend'],
      },
      customerName: {
        title: "患者",
        key: "customerName",
        dataIndex: "customerName",
        // ellipsis: true,
        width: "15%",
        slots: {
          customRender: "customerName"
        },

        sorter: (a, b) => {
          // 定义获取字符串的Unicode编码值总和的逻辑
          const getUnicodeSum = (str) =>
            str ? str.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0) : 0;

          // 检查 name 是否为空，并为 null 或 undefined 提供默认值
          const unicodeSumA = a.customerName ? getUnicodeSum(a.customerName) : 0;
          const unicodeSumB = b.customerName ? getUnicodeSum(b.customerName) : 0;

          if (!a.customerName && b.customerName) return -1; // 如果 a.name 是空，b.name 不是空，则 a 排在前面
          if (a.customerName && !b.customerName) return 1;  // 如果 b.name 是空，a.name 不是空，则 b 排在前面

          if (unicodeSumA < unicodeSumB) return -1;
          if (unicodeSumA > unicodeSumB) return 1;

          // 如果编码相同，可以考虑添加第二个排序标准，比如年龄
          return a.age - b.age;
        },
        sortDirections: ['descend'],
      },
      yunmai: {
        title: "脉诊结果",
        key: "yunmai",
        dataIndex: "yunmai",
        // ellipsis: true,
        width: "15%",
        slots: {
          customRender: "yunmai"
        }
      },
      amount: {
        title: "处方费用（元）",
        key: "amount",
        dataIndex: "amount",
        // ellipsis: true,
        width: "15%",
        slots: {
          customRender: "amount"
        }
      },
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
          this.selectedRows = selectedRows;
        },
        onSelect: (record, selected, selectedRows) => {
          console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          console.log(selected, selectedRows, changeRows);
        }
      },
      editableData: reactive({}),
      selectedRows: {}
      // doExcel: doExcel
    };
  },
  created() { },
  mounted() {
    // this.search("");
    this.getOption();
    this.getLimite();
  },
  activated() {
    if (localStorage.getItem("refrashProject") == "true") {
      // this.search(1);
    }
    localStorage.setItem("refrashProject", false);
  },
  methods: {

    closeModal(value) {
      this[value] = false;
    },
    async exportExcel() {
      let startDate = typeof this.inputs.date[0] == "string" ? this.inputs.date[0] : this.inputs.date[0].format("YYYY-MM-DD")
      let endDate = typeof this.inputs.date[1] == "string" ? this.inputs.date[1] : this.inputs.date[1].format("YYYY-MM-DD")
      doExcel("listReportsTalbe", "清单报表" + startDate + "-" + endDate)
      // // await DownloadSummary({ startDate, endDate, clubId: this.inputs.clubId })
      // let clubId = this.inputs.clubId ? this.inputs.clubId : this.$store.state.club.id
      // console.log(clubId);
      // let host = window.location.protocol + "//" + window.location.hostname
      // let url = `${host}:9443/b2b/PrescriptionFlow/detail/download?startDate=${startDate}&endDate=${endDate}&clubId=${clubId}`
      // console.log(url);

      // window.open(url)
    },
    generatePerformanceVerification() {
      this.visible = true
    },

    getConsultation(record) {
      this.visible = true
      this.selectedRow = { ...record }
    },
    async getOption() {
      let res = await Clubs({ category: '门诊部' })
      this.option = res.data
      console.log(this.option);

    },
    async getLimite() {
      let res = await Limite()
      console.log(res);
      this.canGenerate = res.data
    },
    resetForm() {
      this.inputs = {
        name: undefined,
        gender: undefined,
        isStandard: undefined,
      }
    },
    async onDelete(id) {
      this.editableData = {}
      let res = await Delete({ id })
      this.data = this.data.filter(function (item) {
        return item.id !== id;
      });
    },
    edit(id) {
      let _data = this.data;
      this.editableData[id] = cloneDeep(
        _data.filter(item => id === item.id)[0]
      );
    },
    async save(id) {
      let _data = this.data;
      //判断editableData和修改前是否一致，如果一致则不修改
      if (JSON.stringify(this.data.find((item) => item.id == id)) === JSON.stringify(this.editableData[id])) {
        delete this.editableData[id];
        return;
      }
      let params = {
        id: this.editableData[id].id,
        name: this.editableData[id].name,
        nameInitial: this.editableData[id].nameInitial,
        gender: this.editableData[id].gender,
      }
      console.log(params);
      let res = await Update(params)
      if (res.errCode == 0) {
        Object.assign(
          _data.filter(item => id === item.id)[0],
          this.editableData[id]
        );
        delete this.editableData[id];
      }
    },
    cancel(id) {
      delete this.editableData[id];
    },
    tabsName(tabName) {
      this.$store.commit("changePage", { name: tabName.name, query: tabName.query });
    },
    async search(pagination) {
      let _this = this;
      let startDate = typeof this.inputs.date[0] == "string" ? this.inputs.date[0] : this.inputs.date[0].format("YYYY-MM-DD")
      let endDate = typeof this.inputs.date[1] == "string" ? this.inputs.date[1] : this.inputs.date[1].format("YYYY-MM-DD")
      this.showCount = false

      // this.columns = this.columns.filter(item =>
      //   item => item.title == '日期' || item.title !== '医院名称'
      // )


      console.log(pagination);
      let params = {
        name: this.inputs.name,
        nameInitial: this.inputs.nameInitial,
        gender: this.inputs.gender,
        isStandard: this.inputs.isStandard,
        clubId: this.inputs.clubId,
        category: this.inputs.category,
        startDate,
        endDate,
      };
      if (pagination == "") {
        params.current = 1
      } else {
        params.current = pagination.current
        this.current = pagination.current;
      }

      let res = await Detail(params)
      if (res.errCode == 0) {
        this.columns = [
          {
            title: "序号",
            key: "index",
            dataIndex: "index",
            width: "10%",
            slots: {
              customRender: "index"
            }
          },
          {
            title: "日期",
            key: "date",
            dataIndex: "date",
            width: "15%",
            slots: {
              customRender: "date"
            },
            sorter: (a, b) => new Date(a.date) - new Date(b.date), // 比较两个日期对象
            sortDirections: ['ascend', 'descend'], // 设置允许的排序方向
          },
          {
            title: "医院名称",
            key: "clubName",
            dataIndex: "clubName",
            ellipsis: true,
            width: "30%",
            slots: {
              customRender: "clubName"
            },
          }
        ]
        if (this.inputs.category == '预约') {
          // alert(1)
          this.columns.push(this.doctorName)
          this.columns.push(this.customerName)
        } else if (this.inputs.category == '问诊') {
          this.columns.push(this.customerName)
        } else if (this.inputs.category == '脉诊') {
          this.columns.push(this.customerName)
          this.columns.push(this.yunmai)
        } else if (this.inputs.category == '处方费用') {
          this.columns.push(this.doctorName)
          this.columns.push(this.customerName)
          this.columns.push(this.amount)
          this.showCount = true
          this.count = 0
          res.data.forEach(element => {
            console.log(element.amount);
            console.log(Number(element.amount));

            this.count += element.amount ? Number(element.amount) : 0
          });
          this.count = this.count.toFixed(2)
        }
        if (pagination == "") {
          // this.$message.success("查询成功", function () { });
          this.current = 1;
        }
        this.total = res.data.total;
        this.data = res.data;
        if (this.data.length > 0) {
          this.noData = false
        }
      } else {
        this.$message.error("查询失败", function () { });
      }
    },
    // async changePage(pagination) {
    //   let _this = this;
    //   this.current = pagination.current;
    //   this.editableData = {}
    //   let params = {
    //     name: this.inputs.name,
    //     nameInitial: this.inputs.nameInitial,
    //     gender: this.inputs.gender,
    //     isStandard: this.inputs.isStandard,
    //     current: this.current,
    //     includeMifang: 1,
    //     includeJingfang: 1,
    //   };

    //   let res = await Detail(params)
    //   if (res.errCode == 0) {
    //     this.total = res.data.total;
    //     this.data = res.data.records
    //   } else {
    //     this.$message.error("查询失败", function () { });
    //   }
    // },


  }
};
</script>